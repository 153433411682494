import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state.ts';
import _ from 'lodash'

export const commonSlice = createSlice({
  name: 'commonState',
  initialState,
  reducers: {
    setSpinner: (state, action) => {
      state.spinner = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setProfileActiveTab: (state, action) => {
      state.profileActiveTab = action.payload;
    }
  },
});

export default commonSlice.reducer;
