import * as moment from "moment";
import { Button, Dropdown } from "react-bootstrap";
import arrowUpImage from "./assets/icons/arrowup.svg";
import arrowDownImage from "./assets/icons/arrowdown.svg";
import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import userImageHolder from "./assets/images/user/default-user.svg";
import replyIcon from "./assets/images/icon/reply.svg";
import { MentionsInput, Mention } from "react-mentions";
import { Link } from "react-router-dom";
import profileIcon from "./assets/icons/profile.svg";
import Vimeo from "@u-wave/react-vimeo";
export const getComment = (
  data,
  likeUnlike,
  handleCommentModal,
  handleSetReplyUser,
  assetUrl,
  expandCollapseReply,
  handleExpandCollapseReply,
  setReplyActionModal,
  handleEditReplyCommentModal,
  loadMoreChildReplies,
  isAdmin,
  handleQuoteReply,
  quoteReply,
  handleQuoteCommentChange,
  allUsers,
  sendReply,
  isTopicLocked = 0,
  newComment,
  editReply,
  handleReplyCommentChange,
  replyUserData,
  setParentReplyModal,
  parentReplyModal,
  setParentReplyId,
  parentReplyId,
  sendParentReply,
  topicId,
  replyRef,
  navigateToQuotedReply
) => {
  const {
    _id: replyId,
    imageUrl,
    ownerData = [],
    content = "",
    voteCount: commentVoteCount = 0,
    createdAt: parentCommentCreatedAt,
    replyList = [],
    parentId,
    isLiked = 0,
    level,
    replyCount = 0,
    isOwner = 0,
    isFlagged = 0,
    mediaType = "image",
    isQuote = false,
    quoteReplyId = "",
    quotedText = "",
    showChildOneReplyLoadMore = 0,
    showChildTwoReplyLoadMore = 0,
    fileType = ""
  } = data;
  console.log(quotedText, 'quotedText', replyId, topicId);
  const owner = ownerData[0] || {};
  const onError = (e) => {
    //console.log('ERRor');
    //e.target.src={userImageHolder}
    // alert('Error');
    //console.log(JSON.stringify(userImageHolder));
  };
  let quotedData = quotedText ? JSON.parse(quotedText) || {} : {};
  return (
    <div className="d-flex justify-content-between" style={{ color: "white" }}>
      <div className="user-img position-relative">
        <Link to={"/members/"+owner.username}><img
          onError={onError}
          loading="lazy"
          src={owner.avatar || userImageHolder}
          // src={userImageHolder}
          alt="userimg"
          className="avatar-40 me-3 rounded-circle img-fluid"
        /></Link>
        {owner.isOnline ?
        <span class="avatar-status-reply top25px">
          <i class="material-symbols-outlined text-success  filled">circle</i>
        </span> : ''}
      </div>
      <div className="w-100 text-margin">
        <div className="d-flex justify-content-between align-items-center">
          {/* <div> */}
          <Link to={"/members/"+owner.username}><h5 className="mb-0 d-inline-block me-1">{owner.fullname}</h5></Link>
          {/* <span className=" mb-0 d-inline-block">
                          2 weeks ago
                        </span> */}
          {/* </div> */}
          <div className="d-flex align-items-center">
            <p className="m-0">{moment(parentCommentCreatedAt).fromNow()}</p>
              <div className="card-post-toolbar">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="bg-transparent"
                    style={{
                      padding: 0,
                      verticalAlign: "text-top",
                    }}
                  >
                    <span className="material-symbols-outlined">more_vert</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu m-0 p-0">
                    {(isAdmin || isOwner == 1) && (
                      <Dropdown.Item to="#">
                        <div className="d-flex align-items-top">
                          <div className="h4">
                            <i className="ri-save-line"></i>
                          </div>
                          <div
                            className="data ms-2 d-flex align-items-center"
                            onClick={() =>
                              handleEditReplyCommentModal(
                                true,
                                topicId,
                                replyId,
                                content,
                                assetUrl,
                                imageUrl,
                                level,
                                mediaType,
                                fileType
                              )
                            }
                            style={
                              isTopicLocked === 1
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <span class="material-symbols-outlined">edit</span>
                            <h6>Edit Reply</h6>
                          </div>
                        </div>
                      </Dropdown.Item>
                    )}
                    {(isAdmin || isOwner == 1) && (
                      <Dropdown.Item className="p-3" to="#">
                        <div className="d-flex align-items-top">
                          <i className="ri-close-circle-line h4"></i>
                          <div
                            className="data ms-2 d-flex align-items-center"
                            onClick={() =>
                              setReplyActionModal({
                                value: true,
                                type: "delete",
                                replyId,
                                topicId,
                                level,
                              })
                            }
                            style={
                              isTopicLocked === 1
                                ? { pointerEvents: "none" }
                                : {}
                            }
                          >
                            <span class="material-symbols-outlined">
                              delete
                            </span>
                            <h6>Delete Reply</h6>
                          </div>
                        </div>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item className=" p-3" to="#">
                      <div className="d-flex align-items-top">
                        <i className="ri-user-unfollow-line h4"></i>
                        <div
                          className="data ms-2 d-flex align-items-center"
                          onClick={() =>
                            setReplyActionModal({
                              value: true,
                              type: "report",
                              replyId,
                              topicId,
                              level,
                            })
                          }
                          style={
                              isFlagged == 1
                                ? isTopicLocked
                                  ? { pointerEvents: "none", opacity: "0.4" }
                                  : {}
                                : {}
                          }
                        >
                          <span class="material-symbols-outlined">report</span>
                          <h6>
                            {isFlagged == 0 ? "Report Reply" : "Reported"}
                          </h6>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
          </div>
        </div>
        <div id = {`reply-div-${replyId}`} className={`replied-comments share-icon`} ref={element => (replyRef.current[replyId] = element)}>
          {isQuote ?
          <>
          <div className="share-icon quoted mb-2 cursor-pointer" onClick={e => navigateToQuotedReply(quoteReplyId)}>
            <span className="d-flex align-items-center">
            {quotedData.ownerData && <p className="pre-wrap">{quotedData.ownerData.fullname}   </p>}
            {quotedData.createdAt && <p className="pre-wrap">{moment(quotedData.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>}
            </span>
          <p className="pre-wrap">{displayTextInput(quotedData.content)}</p>
        </div>
        <p className="pre-wrap">{displayTextInput(content)}</p>
        </>
           :
           <p className="pre-wrap">{displayTextInput(content)}</p>
            }
          <span
            className="material-symbols-outlined bg-white position-absolute right10per rounded-circle replyIconspan cursor-pointer"
            onClick={() =>
              {
                setParentReplyId(0);
                setParentReplyModal({});
                handleQuoteReply(
                  replyId,
                  quoteReply[replyId] && quoteReply[replyId].value ? false : true,
                  { value: true },
                )
              }
            }
            style={isTopicLocked === 1 ? { pointerEvents: "none" } : {}}
          >
            <img
              src={replyIcon}
              width={24}
              alt=""
              className="position-relative replyIconfortopics"
            />
          </span>
        </div>
        {mediaType === "image" && imageUrl && (
          <div className="m-3 position-relative">
            <img src={assetUrl + imageUrl} alt="" width="150" />
            {/* <span class="avatar-status"><i class="material-symbols-outlined text-success  md-14 filled">circle</i></span> */}
          </div>
        )}
                {mediaType === "video" && imageUrl && fileType === "link" ?
                <div className="m-3 position-relative">
                                          <Vimeo
                                          video={imageUrl}
                                          className="vimeo_video"
                                        />
                                        </div>
                 : mediaType === "video" && imageUrl && fileType === "upload" && (
          <div className="m-3 position-relative">
                                <video controls width="250" controlsList="nodownload">
                      <source src={assetUrl + imageUrl} />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
            {/* <span class="avatar-status"><i class="material-symbols-outlined text-success  md-14 filled">circle</i></span> */}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center flex-wrap mt-20">
          <div className="d-flex justify-content-around align-items-center">
            <div
              className={`d-flex align-items-center me-3 cursor-pointer padding4 ${
                !isLiked ? "" : "liked"
              }`}
              onClick={() =>
                likeUnlike(
                  replyId,
                  isLiked ? "down" : "up",
                  "reply",
                  parseInt(level)
                )
              }
            >
              <span className="card-text-1 ms-1">Like</span>
            </div>
            {parseInt(level) < 2 && (
              <div className="d-flex align-items-center me-3">
                <span className="card-text-1 ms-1">|</span>
              </div>
            )}
            {parseInt(level) < 2 && (
              <div
                className="d-flex align-items-center me-3 cursor-pointer"
                onClick={() => {
                  setParentReplyId(replyId);
                  setParentReplyModal({
                    ...parentReplyModal,
                    [replyId] : {
                      ...parentReplyModal[replyId],
                      value: true,
                      topicId,
                      replyId,
                      level: parseInt(level) + 1,
                      fullname: owner.fullname,
                      modal: false,
                      fileType
                    }
                  })
                  handleQuoteReply(0, false, {}, true)
                }}
                style={isTopicLocked === 1 ? { pointerEvents: "none" } : {}}
              >
                <span className="card-text-1 ms-1">Reply</span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-around align-items-center">
            {commentVoteCount > 0 && (
              <div className="d-flex align-items-center me-3">
                <span className="card-text-1 ms-1">
                  {commentVoteCount} {commentVoteCount == 1 ? "Like" : "Likes"}
                </span>
              </div>
            )}
            {replyCount > 0 && (
              <div
                className="d-flex align-items-center me-3 cursor-pointer"
                onClick={() => handleExpandCollapseReply(replyId)}
              >
                <span className="card-text-1 ms-1">{replyCount} Replies</span>
                <img
                  src={
                    !expandCollapseReply[replyId]
                      ? arrowUpImage
                      : arrowDownImage
                  }
                  alt="arrow"
                />
              </div>
            )}
          </div>
        </div>
        {parentReplyModal[replyId] && parentReplyModal[replyId].value && <div className="comment-area mt-3 mb-3 d-flex align-items-center">
          <form className="comment-text create-comments width-100">
            <MentionsInput
              aria-label={"comment-" + replyId}
              title={"comment-" + replyId}
              id={"comment-" + replyId}
              name={"comment-" + replyId}
              value={parentReplyModal[replyId] ? parentReplyModal[replyId].parentComment ?
                parentReplyModal[replyId].parentComment : "" : "" }
              onChange={(event, newValue, newPlainTextValue, mentions) =>
                handleReplyCommentChange(
                  event,
                  newValue,
                  newPlainTextValue,
                  mentions,
                  replyId,
                  topicId
                )
              }
              markup="@{{_display|id_}}"
              onKeyDown={evt => {
                if(evt.keyCode == 13 && !evt.shiftKey && parentReplyModal[replyId] && parentReplyModal[replyId].parentComment) {
                  sendParentReply(
                    topicId,
                    parentReplyModal[replyId] ? parentReplyModal[replyId].parentComment ?
                    parentReplyModal[replyId].parentComment : "" : "",
                    parseInt(level) + 1,
                    replyId,
                    replyId,
                    [], 
                    [],
                    fileType
                  )
                }
              }}
              //   maxLength="2000"
              placeholder="Enter your comment"
              className="mentions radiusborder20 color-white"
              autoFocus
              allowSuggestionsAboveCursor={true}
            >
              <Mention
                type="user"
                trigger="@"
                data={allUsers}
                className="mentions__mention  h-250 overflow-scroll-y"
                displayTransform={(input) => `@${getUserName(allUsers, input)}`}
                appendSpaceOnAdd={true}
                style={{ backgroundColor: "#cee4e5 !important" }}
                renderSuggestion={(
                  e,
                  search,
                  highlightedDisplay,
                  index,
                  focused
                ) => {
                  return (
                    true && (
                      <div className="d-flex align-items-center">
                        <img
                          loading="lazy"
                          src={e.avatar || profileIcon}
                          alt="userimg"
                          className="avatar-40 me-3 rounded-circle img-fluid "
                          autoFocus
                        />
                        <div className="d-flex flex-column align-self-end ">
                          <p className="font14 fweight700 line-height-16 m-0  ">
                            <p className="color-white">{e.display}</p>
                          </p>
                          <p className="color-white">@{e.username}</p>
                        </div>
                      </div>
                    )
                  );
                }}
              />
            </MentionsInput>
            <div className="comment-attagement d-flex photo_camera">
                      <Link
                        to="#"
                        className="material-symbols-outlined filled  me-3 cursor-pointer"
                        onClick={() => {
                          setParentReplyId(replyId)
                          setParentReplyModal({
                            ...parentReplyModal,
                            [replyId] : {
                              ...parentReplyModal[replyId],
                              // value: false,
                              topicId,
                              replyId,
                              level: parseInt(level) + 1,
                              fullname: owner.fullname,
                              modal: true,
                              newComment: parentReplyModal[replyId] ? parentReplyModal[replyId].parentComment || "" : "",
                              fileType
                            }
                          })
                          // handleSetReplyUser(
                          //   true,
                          //   owner.fullname,
                          //   parseInt(level) + 1,
                          //   replyId,
                          //   true
                          // );
                          // handleCommentModal(true, topicId);
                        }}
                      >
                        photo_camera
                      </Link>
                    </div>
          </form>
          <div className="d-flex align-items-center">
          <Button
              onClick={() => {
                setParentReplyId(0);
                setParentReplyModal({
                  ...parentReplyModal,
                  [replyId] : {
                    ...parentReplyModal[replyId],
                    value: false,
                    topicId: "",
                    replyId: "",
                    level: -1,
                    fullname: "",
                    modal: false,
                    fileType: ""
                  }
                })
              }}
              className="reply-button"
              size="sm"
              style={{marginRight: 5}}
            >
              Cancel
            </Button>
            <Button
              disabled={!parentReplyModal[replyId] || !parentReplyModal[replyId].parentComment}
              onClick={() => {
                sendParentReply(
                  topicId,
                  parentReplyModal[replyId] ? parentReplyModal[replyId].parentComment ?
                  parentReplyModal[replyId].parentComment : "" : "",
                  parseInt(level) + 1,
                  replyId,
                  replyId,
                  [], 
                  [],
                  fileType
                )
              }}
              className="reply-button"
              size="sm"
            >
              Reply
            </Button>
          </div>
        </div>}
        {quoteReply[replyId] && quoteReply[replyId].value && (
          <div className="w-100 text-margin">
            <div className="quoted-wrapper">
            <div className="share-icon quoted">
            <p className="pre-wrap mb-1 quote-owner">{owner.fullname}</p>
              <p className="pre-wrap quote-text">{displayTextInput(content, true)}</p>
            </div>
            </div>
            {imageUrl && (
              <div className="m-3">
                <img src={assetUrl + imageUrl} alt="" width="150" />
              </div>
            )}
            <div className="comment-area mt-3 d-flex align-items-center">
              <form className="comment-text create-comments width-100">
                <MentionsInput
                  aria-label={"comment-" + replyId}
                  title={"comment-" + replyId}
                  id={"comment-" + replyId}
                  name={"comment-" + replyId}
                  value={
                    quoteReply[replyId] ? quoteReply[replyId].comment || "" : ""
                  }
                  onChange={(event, newValue, newPlainTextValue, mentions) =>
                    handleQuoteCommentChange(
                      event,
                      newValue,
                      newPlainTextValue,
                      mentions,
                      replyId
                    )
                  }
                  onKeyDown={evt => {
                    if (evt.keyCode == 13 && !evt.shiftKey && quoteReply[replyId] && quoteReply[replyId].comment) {
                      sendReply(
                        topicId,
                        quoteReply[replyId].comment,
                        parseInt(level),
                        parentId,
                        replyId,
                        true,
                        fileType
                      );
                    }
                  }
                }
                  markup="@{{_display|id_}}"
                  //   maxLength="2000"
                  placeholder="Enter your comment"
                  className="mentions radiusborder20 color-white"
                  autoFocus
                  allowSuggestionsAboveCursor={true}
                >
                  <Mention
                    type="user"
                    trigger="@"
                    data={allUsers}
                    className="mentions__mention h-100 overflow-scroll-y"
                    displayTransform={(input) =>
                      `@${getUserName(allUsers, input)}`
                    }
                    appendSpaceOnAdd={true}
                    style={{ backgroundColor: "#cee4e5 !important" }}
                    renderSuggestion={(
                      e,
                      search,
                      highlightedDisplay,
                      index,
                      focused
                    ) => {
                      return (
                        true && (
                          <div className="d-flex align-items-center">
                            <img
                              loading="lazy"
                              src={e.avatar || profileIcon}
                              alt="userimg"
                              className="avatar-40 me-3 rounded-circle img-fluid "
                              autoFocus
                            />
                            <div className="d-flex flex-column align-self-end ">
                              <p className="font14 fweight700 line-height-16 m-0  ">
                                <p className="color-white">{e.display}</p>
                              </p>
                              <p className="color-white">@{e.username}</p>
                            </div>
                          </div>
                        )
                      );
                    }}
                  />
                </MentionsInput>
              </form>
              <div className="d-flex align-items-center">
              <Button
              onClick={() => {
                handleQuoteReply(
                  replyId,
                  quoteReply[replyId] && quoteReply[replyId].value ? false : true,
                  { value: false }
                )
              }}
              className="reply-button"
              size="sm"
              style={{marginRight: 5}}
            >
              Cancel
            </Button>
                <Button
                  disabled={
                    !quoteReply[replyId] || !quoteReply[replyId].comment
                  }
                  onClick={() => {
                    sendReply(
                      topicId,
                      quoteReply[replyId].comment,
                      parseInt(level),
                      parentId,
                      replyId,
                      true,
                      fileType
                    );
                  }}
                  className="reply-button"
                  size="sm"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
        {!expandCollapseReply[replyId] && (
          <ul className="post-comments p-0 mt-4 text-dark">
            {replyList.map((childReply) => {
              return (
                <li className="mb-2">
                  {getComment(
                    childReply,
                    likeUnlike,
                    handleCommentModal,
                    handleSetReplyUser,
                    assetUrl,
                    expandCollapseReply,
                    handleExpandCollapseReply,
                    setReplyActionModal,
                    handleEditReplyCommentModal,
                    loadMoreChildReplies,
                    isAdmin,
                    handleQuoteReply,
                    quoteReply,
                    handleQuoteCommentChange,
                    allUsers,
                    sendReply,
                    0,
                    newComment,
                    editReply,
                    handleReplyCommentChange,
                    replyUserData,
                    setParentReplyModal,
                    parentReplyModal,
                    setParentReplyId,
                    parentReplyId,
                    sendParentReply,
                    topicId,
                    replyRef,
                    navigateToQuotedReply
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {replyList && replyList.length < replyCount && (parseInt(level) == 1  ? showChildOneReplyLoadMore : 
          parseInt(level) == 2 ? showChildTwoReplyLoadMore : true) && (
          <div
            className="d-flex align-items-center"
            onClick={() =>
              loadMoreChildReplies(topicId, replyId, parseInt(level) + 1)
            }
          >
            <div className="d-flex align-items-center me-3 cursor-pointer">
              <span className="card-text-1 ms-1">
                <u>Load more</u>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export const checkAdmin = () => {
  let isAdmin = false;
  const userData = localStorage.getItem("userData") || "";
  if (userData) {
    const parsed = JSON.parse(userData);
    isAdmin = parsed.usergroup == 1 || parsed.usergroup == 2 || false;
  }
  return isAdmin;
};
export function displayTextInput(text = "", isQuote = false) {
  let displayText = _clone(text);
  const tags = text.match(/@\[(.*?)\]\((.*?)\)/gi) || [];
  tags.map((myTag) => {
    const value = myTag.match(/\[(.*?)\]/gi) || [];
    const tagData = value[0] && value[0].replace("[", "").replace("]", "");
    const tagDisplayValue = tagData;
    displayText = displayText.replace(
      new RegExp(_escapeRegExp(myTag), "gi"),
      tagDisplayValue
    );
  });
  console.log(displayText, 'displayText');
  return displayText;
}
export const getUserName = (allUsers = [], id) => {
  const data = allUsers.filter((option) => option.id === id)[0]
    ? allUsers.filter((option) => option.id === id)[0].display
      ? allUsers.filter((option) => option.id === id)[0].display
      : ""
    : "";
  return data;
};

export const isValidUrl = (string="") => {
  let res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
};

export const createNotificationStatement = (data) => {

  let item = data.item;
  let action = data.action;
  let ownerId = data.ownerId ? data.ownerId : 0;
  let owner = data.ownerInfo ? data.ownerInfo[0] : {};
  let sender = data.senderInfo ? data.senderInfo[0] : {};
  let user = data.userInfo ? data.userInfo[0] : {};

  //let content = ownerId ? <a href={`/members/${owner.username}`}>{`${owner.fullname}`}</a>  : 'You ';

  let content = owner ? owner.fullname : "You "
  if (
    item === "course_category" &&
    (action === "add" || action === "edit" || action === "delete")
  ) {
    return (
      <>
        <h6>{`${data.categoryName}`}</h6>{" "}
        <p>{`${content} ${action}`}ed this Resource Category.</p>
      </>
    );
  }else if (
    item === "email_template" &&
    (action === "add" || action === "edit" || action === "delete")
  ) {
    return (
      <>
        <p>{`${content} ${action}`}ed email template.</p>
      </>
    );
  } else if (
    item === "course_content" &&
    (action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "start" ||
      action === "complete")
  ) {
    return (
      <>
        <h6>
          <a href={`/content/${data.slug}`}>{`${data.title}`}</a>
        </h6>{" "}
        <p>{`${content} ${action}`} ed this Resource Module.</p>
      </>
    );
  } else if (
    item === "course" &&
    (action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "complete")
  ) {
    return (
      <>
        <h6>
          <a
            href={`/course/${data.categorySlug}/${data.slug}`}
          >{`${data.title}`}</a>
        </h6>{" "}
        <p>{`${content} ${action}`}ed this Resource.</p>
      </>
    );
  } else if (
    item === "forum_category" &&
    (action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "subscribe" ||
      action === "unsubscribe")
  ) {
    return (
      <>
        <h6>{`${data.categoryName}`}</h6>{" "}
        <p>{`${content} ${action}`}ed this Forum Category.</p>
      </>
    );
  } else if (item === "message") {
    if (action === "add") {
      return (
        <>
          <h6 dangerouslySetInnerHTML={{ __html: data.content }}></h6>{" "}
          <p>
            {`${content}`} sent message to{" "}
            <a href={`/members/${sender.username}`}>{`${sender.fullname}`}</a>
            .
          </p>
        </>
      );
    } else if (action === "delete_chat") {
      return (
        <>
          <h6>Chat Deleted Successfully</h6>{" "}
          <p>
            {`${content}`} removed the chat data from this user{" "}
            <a href={`/members/${sender.username}`}>{`${sender.fullname}`}</a>
            .
          </p>
        </>
      );
    } else if (action === "like" || action === "unlike") {
      return (
        <>
          <h6>{`${data.content}`}</h6>{" "}
          <p>
            {`${content} ${action}`}ed this user message to{" "}
            <a href={`/members/${sender.username}`}>{`${sender.fullname}`}</a>
            .
          </p>
        </>
      );
    }
  } else if (item === "reply") {
    if (
      action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "up" ||
      action === "down"
    ) {
      if (action === "up") {
        action = "liked";
      } else if (action === "down") {
        action = "disliked";
      } else if (action === "delete") {
        return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.topic.slug}`}
            >{`${data.topic.title}`}</a>
          </h6>{" "}
          <p>
            {`${content}`} deleted one reply content from this topic.
          </p>
        </>
      );
      }  else {
        action = action + "ed";
      }
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.topic.slug}`}
            >{`${data.topic.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} ${action}`} this reply " {`${data.reply.content}`}" Content.
          </p>
        </>
      );
    } else if (action === "move") {
      return (
        <>
          <h6>Moved Topic Replies!</h6>
          <p>
            {`${content}`} moved some replies content from this{" "}
            <a
              href={`/community/${data.fromCategorySlug.categorySlug}/${data.fromTopicInfo.slug}`}
            >{`${data.fromTopicInfo.title}`}</a>{" "}
            topic to this{" "}
            <a
              href={`/community/${data.topic.toCategorySlug}/${data.toTopicInfo.slug}`}
            >{`${data.toTopicInfo.title}`}</a>{" "}
            topic.
          </p>
        </>
      );
    } else if (action === "flag") {
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.topicInfo.slug}`}
            >{`${data.topicInfo.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} ${action}`} this reply "{`${data.replyInfo.content}`}
            ".
          </p>
        </>
      );
    }
  } else if (item === "topic") {
    if (
      action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "lock" ||
      action === "unlock" ||
      action === "pin" ||
      action === "unpin" ||
      action === "up" ||
      action === "down" ||
      action === "subscribe" ||
      action === "unsubscribe"
    ) {
      if (action === "up") {
        action = "liked";
      } else if (action === "down") {
        action = "disliked";
      } else if (action === "subscribe") {
        action = "subscribed";
      } else if (action === "unsubscribe") {
        action = "unsubscribed";
      } else {
        action = action + "ed";
      }
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.slug}`}
            >{`${data.title}`}</a>
          </h6>{" "}
          <p>{`${content} ${action}`} this topic.</p>
        </>
      );
    } else if (action === "moved") {
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categoryInfo.slug}/${data.topicInfo.slug}`}
            >{`${data.topicInfo.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} ${action}`}ed this topic from this category{" "}
            {`${data.oldCategoryInfo.categoryName}`} to this category{" "}
            {`${data.categoryInfo.categoryName}`}.
          </p>
        </>
      );
    }
  } else if (item === "user") {
    if (
      action === "follow" ||
      action === "unfollow" ||
      action === "add_friend" ||
      action === "remove_friend" ||
      action === "accept_friend" ||
      action === "flag" ||
      action === "email_verified"
    ) {
      if (action === "add_friend") {
        action = "sent friend request to ";
      } else if (action === "remove_friend") {
        action = "removed or cancelled the freind request from ";
      } else if (action === "accept_friend") {
        action = "accepted friend request from ";
      } else if (action === "email_verified") {
        action = "verified the email and profile activated and also joined with this user network";
      } else {
        action = action + "ed";
      }
      return (
        <>
          <h6>
            {`${content} ${action}`}{" "}
            <a href={`/members/${user.username}`}>{`${user.fullname}`}</a>
          </h6>
        </>
      );
    }
  } else if (item === "reward") {
    if (action === "like_topic") {
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.slug}`}
            >{`${data.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} earned ${data.earned} reward points for liked the topic.`}
            .
          </p>
        </>
      );
    } else if (action === "subscribe_topic") {
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.slug}`}
            >{`${data.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} earned ${data.earned} reward points for subscribed the topic.`}
            .
          </p>
        </>
      );
    } else if (action === "like_reply") {
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.slug}`}
            >{`${data.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} earned ${data.earned} reward points for liked this reply "${data.content}" content.`}
            .
          </p>
        </>
      );
    } else if (action === "add_reply") {
      return (
        <>
          <h6>
            <a
              href={`/community/${data.categorySlug}/${data.slug}`}
            >{`${data.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} earned ${data.earned} reward points for added the new reply "${data.content}" content.`}
            .
          </p>
        </>
      );
    } else if (action === "course_completed") {
      return (
        <>
          <h6>
            <a
              href={`/course/${data.categorySlug}/${data.slug}`}
            >{`${data.title}`}</a>
          </h6>{" "}
          <p>
            {`${content} earned ${data.earned} reward points for completed the resource successfully.`}
            .
          </p>
        </>
      );
    } else if (action === "new_user_registered") {
      action = 'earned '+data.earned+' reward points for user joined with your referral code or from your network'
      return (
        <>
          <h6> 
            <a href={`/members/${user.username}`}>{`${user.fullname}`}</a>
          </h6>
          <p>
            {`${content} ${action}`}{" "}
          </p>
        </>
      );
    } else if (action === "login") {
      return (
        <>
          <h6>
            {`${content} earned ${data.earned} reward points for the daily login activity.`}
          </h6>
        </>
      );
    }  
  }
};

export const  handleScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export const isValidateDOB = () => {
  var date = new Date();
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  //Add a zero if one Digit (eg: 05,09)
  if (dd < 10) {
    dd = "0" + dd;
  }
  //Add a zero if one Digit (eg: 05,09)
  if (mm < 10) {
    mm = "0" + mm;
  }
  let minYear = yyyy - 80; //Calculate Minimun Age (<80)
  let maxYear = yyyy - 18; //Calculate Maximum Age (>18)

  var min = minYear + "-" + mm + "-" + dd;
  var max = maxYear + "-" + mm + "-" + dd;
  console.log(min, max, 'min');
  return {min, max};
}