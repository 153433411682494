import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state.ts';
import { updateStorage, getStorage } from '../../utilities/setting'
import _ from 'lodash'

export const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const userData = getStorage(state.userData);
      if(userData !== null && userData !== '') {
        state.userData = userData;
      }
      updateStorage('localStorage', "userData", state)
    }
  },
});

export default signInSlice.reducer;
