//router
import IndexRouters from "./router/index";
//scss
import "./assets/scss/socialv.scss";
import "./assets/scss/customizer.scss";
import Snackbar from "./components/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { snackbarValue, spinnerValue } from "./store/common/selectors";
import { setSnackbar } from "./store/common/actions";
import { useEffect } from "react";
import { theme_scheme } from "./store/setting/actions";
import { authenticate } from "./app/Auth";
import LoadingOverlay from "react-loading-overlay";
import ScrollButton from "./components/ScrollButton";
import { ENVIRONMENT } from "./Constants";
function App() {
  const dispatch = useDispatch();
  const spinner  = useSelector(spinnerValue);
  const snackbar = useSelector(snackbarValue);

  //const ENV = process.env.ENVIRONMENT || "PROD"; //PROD or DEV
  const ENV = ENVIRONMENT || "PROD"; //PROD or DEV

  useEffect(() => {
    const userData = localStorage.getItem("userData") || "";
    if (userData) {
      authenticate(JSON.parse(userData));
    }
    if (ENV === "PROD") {
      document.addEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
      document.addEventListener("keydown", function (e) {
        if (
          e.key === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.key === "U".charCodeAt(0))
        )
          e.preventDefault();
      });
    }
  }, []);

  const ctrlShiftKey = (e, keyCode) => {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  return (
    <LoadingOverlay
      active={spinner}
      spinner={spinner}
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: "unset",
        },
        overlay: (base) => ({
          ...base,
          background: "rgba(0,0,0,0.3)",
        }),
      }}
    >
      <div className="App">
        <IndexRouters />
        <Snackbar
          snackbar={snackbar}
          handleClose={() =>
            dispatch(setSnackbar({ ...snackbar, open: false, message: "" }))
          }
        />
      </div>
      <ScrollButton />
    </LoadingOverlay>
  );
}
export default App;
