import React, {useState} from 'react';
import styled from 'styled-components';
import {faArrowAltCircleUp} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 export const Content = styled.div`
overflowY: scroll;
height: 2500px;
`;
export const Heading = styled.h1`
text-align: right;
`;
export const Button = styled.div`
position: fixed;
width: 100%;
left: 95%;
bottom: 40px;
height: 20px;
font-size: 24px;
z-index: 1;
cursor: pointer;
color: green;
`
const ScrollButton = () =>{
const [visible, setVisible] = useState(false)
const toggleVisible = () => {
	const scrolled = window.scrollY;
	if (scrolled > 300){
	setVisible(true)
	}
	else if (scrolled <= 300){
	setVisible(false)
	}
};
const scrollToTop = () =>{
	window.scrollTo({
	top: 0,
	behavior: 'smooth'
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};
window.addEventListener('scroll', toggleVisible);
return (
	<Button onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}} >
    <FontAwesomeIcon icon={faArrowAltCircleUp} size="1x" className='color-primary' />
	</Button>
);
}
export default ScrollButton;
