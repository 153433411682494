export const commentSortingOptions = {
  recent: { label: "Most recent", icon: "arrow_drop_down" },
  old: { label: "Old replies", icon: "arrow_drop_up" },
};
export const sortingOptions = {
  recent: { label: "Recent Topics", icon: "arrow_drop_down" },
  old: { label: "Old Topics", icon: "arrow_drop_up" },
};
export const courseSortingOptions = {
  new: { label: "Recent", icon: "arrow_drop_down" },
  old: { label: "Old", icon: "arrow_drop_up" },
  popular: { label: "Popular", icon: "arrow_drop_up" },
  trending: { label: "Trending", icon: "arrow_drop_up" },
};
export const currencies = [{ label: "USD", value: "USD" }];

export const alphaNumeric = /[^0-9a-zA-Z]/;

export const onlyNumber = /^[0-9\b]+$/;

export const defaultReferralCode = "2e2e915eb07c5b74ceb6";

export const defaultCourseContentUrl =
  "/modules/introduction-to-cryptoati/the-2-most-powerful-concepts-for-making-and-multiplying-money";
export const DEFAULTCOURSEID = "631a9ae74c0c0812d640dab1";
export const DEFAULTCOURSECONTENTID = "631dff3b7b40cdd8ba9507f1";

export const affliateDomains = [
  "www.cryptoati.io",
  "www.influati.com",
  "www.treasurex.com",
];

export const ENVIRONMENT = "PROD"; // DEV

// Demo
// export const BASE_URL_ONE = "http://3.98.211.88:3004/";
// export const BASE_URL_TWO = "http://3.98.211.88:3003/";
// export const SITE_URL     = "http://3.98.211.88:4567/";

// Live
export const BASE_URL_ONE = "https://cryptoati.io/";
export const BASE_URL_TWO = "https://cryptoati.io/";
export const SITE_URL     = "https://cryptoati.io/";

//Local
// export const BASE_URL_ONE = "http://localhost:3002/";
// export const BASE_URL_TWO = "http://localhost:3001/";
// export const SITE_URL = "http://localhost:3000/";

// paths
//community
export const CONFIRM_MAIL = "/confirm-mail";
export const LOCK_SCREEN = "/lock-screen";
export const RECOVER_PW = "/forgot-password/:email?/:token?";
export const VERIFY_ACCOUNT = "/account-verify";
export const APPROVE_IP = "/approveIp";
export const SIGNIN = "/signin";
export const SIGNUP = "/signup";
export const ERROR404 = "/error404";
export const ERROR500 = "/error500";
export const HOME = "/";
export const ABOUT = "/about";
export const EARNINGSDISCLAIMER = "/earnings-disclaimer";
export const PRIVACYPOLICY = "/privacy-policy";
export const TERMSOFSERVICES = "/terms-for-services";
export const PROFILE = "/members/:username?/:tab?";
export const MEMBERS = "/members";
export const FRIENDLIST = "/friend-list";
export const MESSAGING = "/message/:senderId?";
export const HOMEFORUM = "/forum-categories";
export const NOTIFICATION = "/notifications";
export const SEARCH = "/search";
export const COURSES = "/member-resources";
export const COURSE_DETAIL = "/training/:category_slug/:course_title_slug";
export const COURSE_CONTENT_DETAIL =
  "/modules/:course_title_slug/:course_content_title_slug";
export const COURSE_ADD_EDIT = "/course/action/:action/:id?";
export const TOPIC_DETAIL =
  "/community/:category_slug/:topic_title_slug/:mode?";
export const TOPIC_DETAIL_BASE_URL = "/community";
export const ADD_EDIT_TOPIC =
  "/topic/:action/:category_slug?/:topic_title_slug?";
export const REFERRAL_LANDING_PAGE = "/vip-invite";

// Mobile view Route
export const CREATEEDITTOPIC = "/topic/:action/:category_slug?/:topic_title_slug?";

//messaging
export const SCREEN1 = "/screen1";
export const SCREEN2 = "/screen2";
export const SCREEN3 = "/screen3";

export const COMMUNITY_URLS = [
  HOME,
  PROFILE,
  FRIENDLIST,
  MESSAGING,
  COURSES,
  TOPIC_DETAIL,
  TOPIC_DETAIL_BASE_URL,
];

//admin
export const ADMIN = "/admin";

export const ADMIN_FORUM_CATEGORIES = "/admin/forum-categories";
export const ADMIN_ADD_EDIT_FORUM_CATEGORIES =
  "/admin/forum-categories/:action/:id?";

export const ADMIN_USERS = "/admin/users";
export const ADMIN_USER_ADD_EDIT = "/admin/users/:action/:id?";

export const ADMIN_TOPIC_LIST = "/admin/topics";
export const ADMIN_REPLY_LIST = "/admin/replies";
export const ADMIN_REPORT_LIST = "/admin/flags";
export const ADMIN_SUBSCRIPTION_LIST = "/admin/user-subscribtions";

export const ADMIN_TAG_LIST = "/admin/tags";
export const ADMIN_TAG_ADD_EDIT = "/admin/tags/:action/:id?";

export const ADMIN_RESOURCE_CATEGORIES = "/admin/resource-categories";
export const ADMIN_ADD_EDIT_RESOURCE =
  "/admin/resource-categories/:action/:id?";

export const ADMIN_RESOURCE_LIST = "/admin/resources";
export const ADMIN_ENROLLED_RESOURCE_LIST = "/admin/resource-enrolled-user";
export const ADMIN_RESOURCE_PAYMENT_HISTORY_LIST =
  "/admin/resource-payment-histories";

export const ADMIN_EMAIL_TEMPLATES_LIST = "/admin/email-templates";
export const ADMIN_ADD_EDIT_EMAIL_TEMPLATES =
  "/admin/email-templates/:action/:id?";

export const ADMIN_PAGE_LIST = "/admin/pages";
export const ADMIN_ADD_EDIT_PAGE = "/admin/pages/:action/:id?";

export const ADMIN_USER_LEVELS_LIST = "/admin/user-levels";
export const ADMIN_ADD_EDIT_USER_LEVELS = "/admin/user-levels/:action/:id?";

export const ADMIN_URLS = [
  ADMIN,
  ADMIN_FORUM_CATEGORIES,
  ADMIN_ADD_EDIT_FORUM_CATEGORIES,
  ADMIN_TOPIC_LIST,
  ADMIN_REPLY_LIST,
  ADMIN_REPORT_LIST,
  ADMIN_SUBSCRIPTION_LIST,
  ADMIN_USERS,
  ADMIN_USER_ADD_EDIT,
  ADMIN_TAG_LIST,
  ADMIN_TAG_ADD_EDIT,
  ADMIN_RESOURCE_CATEGORIES,
  ADMIN_ADD_EDIT_RESOURCE,
  ADMIN_RESOURCE_LIST,
  ADMIN_ENROLLED_RESOURCE_LIST,
  ADMIN_RESOURCE_PAYMENT_HISTORY_LIST,
  ADMIN_EMAIL_TEMPLATES_LIST,
  ADMIN_ADD_EDIT_EMAIL_TEMPLATES,
  ADMIN_PAGE_LIST,
  ADMIN_ADD_EDIT_PAGE,
  ADMIN_USER_LEVELS_LIST,
  ADMIN_ADD_EDIT_USER_LEVELS,
];

//export const SVGIcon = [{HomeIcon: '<svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 15c2.902 0 5.25-2.46 5.25-5.5S11.902 4 9 4 3.75 6.46 3.75 9.5 6.098 15 9 15Zm3.6 1.571h-.39a7.11 7.11 0 0 1-3.21.786c-1.153 0-2.231-.294-3.21-.786H5.4c-2.981 0-5.4 2.534-5.4 5.658v1.414C0 24.944 1.008 26 2.25 26h13.5c1.242 0 2.25-1.056 2.25-2.357v-1.414c0-3.124-2.419-5.658-5.4-5.658ZM22.5 15c2.484 0 4.5-2.112 4.5-4.714 0-2.603-2.016-4.715-4.5-4.715S18 7.683 18 10.286C18 12.888 20.016 15 22.5 15Zm2.25 1.571h-.178c-.652.236-1.34.393-2.072.393-.731 0-1.42-.157-2.072-.393h-.178c-.956 0-1.837.29-2.61.757a7.374 7.374 0 0 1 1.86 4.9v1.886c0 .108-.023.211-.028.315h8.278c1.242 0 2.25-1.056 2.25-2.358 0-3.04-2.348-5.5-5.25-5.5Z" fill="#fff" /></svg>'}];

export const GENERATION_LAMDA_API =
  "https://kzugfta2e6zzwfnvf4r43w7zci0mipli.lambda-url.ca-central-1.on.aws";

export const API_CALL_LIMITATION = 10;
