import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state.ts';
import _ from 'lodash'

export const homeSlice = createSlice({
  name: 'homeState',
  initialState,
  reducers: {
    setHomeData: (state, action) => {
      state[action.payload.property] = action.payload.value;
    },
    setMultiHomeData: (state, action) => {
      state = action.payload;
    }    
  },
});

export default homeSlice.reducer;
