import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import signInReducer from './signIn/reducers';
import commonReducer from './common/reducers';
import homeReducer from './home/reducers';

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    signIn: signInReducer,
    commonState: commonReducer,
    homeState: homeReducer
  }
});
