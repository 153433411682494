import axios from "axios";
import { BASE_URL_ONE, BASE_URL_TWO, HOME, SIGNIN } from "../Constants";

const Auth = {
  instance: axios.create({
    baseURL: `${BASE_URL_ONE}communityapi/`,
    headers: {
      "X-Frame-Options": "deny"
    }
  }),
  instanceTwo: axios.create({
    baseURL: `${BASE_URL_TWO}api/`,
  }),

  isGoogleAuth: false,

  isKyc: false,

  isAuthenticated: false,

  email: "",

  phoneNumber: 0,

  referralCode: "",

  accessToken: "",

  refreshToken: "",

  themeColor: "dark",

  fullName: "",
  address: "",

  codes: {
    109: "Incorrect GoogleAuth secret key",
    110: "Unable to verify your email, please generate a new verification link",
    111: "Email verification link expired, please generate a new verification link",
    112: "Account email does not exists in our database",
    113: "You have insufficient balance for this transaction",
    114: "User not available",
    115: "Current password entered is incorrect",
    116: "Incorrect GoogleAuth token",
    117: "Please enter GoogleAuth token",
    118: "Link expired",
    119: "User not available",
    120: "Incorrect password",
    121: "Please verify your account from the link received in mail",
    122: "This account has been disabled",
    123: "Please approve the new IP from email",
    124: "Email is either verified or has not been registered",
    125: "Email address or username already register with us",
    127: "Email has already been sent for password reset. Please check your email",
    128: "Social Media Activity Already Exist",
    129: "OTP Expired",
    126: "Referral Code is Invalid!",
    131: "Quantity not available",
    400: "Invalid input",
    422: "Invalid input",
    401: "Unauthorized action",
    403: "Session, expired, please login again",
    500: "Some error occurred, please try again later",
    600: "Please check your internet connection status",

    204: "Your password has been changed successfully. You can now login with your new password",
    205: "Your password has been changed successfully!",
    206: "Order placed successfully!",
    207: "Order cancelled successfully!",
    208: "Your Email ID has been registered successfully!",
    209: "Email has been verified. Please login to continue",
    210: "IP added. Please login to continue",
    211: "Your message has been sent successfully. We will get back to soon.",
    212: "Transaction locked successfully",
    214: "Request Submitted",
    300: "Approve your IP first",
    447: "Insufficient Balance",
  },

  authenticate(authData) {
    Auth.isAuthenticated = true;
    Auth.email = authData["email"];
    Auth.accessToken = authData["accessToken"];
    Auth.refreshToken = authData["refreshToken"];
    Auth.instance.defaults.headers.common["Authorization"] =
      authData["accessToken"];
    Auth.instance.CancelToken = axios.CancelToken;
    Auth.instanceTwo.defaults.headers.common["Authorization"] =
    authData["accessToken"];
  Auth.instanceTwo.CancelToken = axios.CancelToken;
    Auth.isGoogleAuth = authData["tfa"] === 1 ? true : false;
    Auth.isIpWhiteList = authData["isIpWhiteList"] === 1 ? true : false;
    Auth.phoneNumber = authData["phoneNumber"];
    Auth.referralCode = authData["referralCode"];
    Auth.isKyc = authData["isKyc"] === 1 ? true : false;
    Auth.fullName = authData["fullName"];
    Auth.address = authData["address"];
  },

  async httpRequest({
    type,
    endpoint,
    formData,
    isPublic = true,
    token,
    callBack,
    time = 1,
    instance = "instance",
    config = {}
  }) {
    let response = "";
      switch (type) {
        case "get":
          response = await Auth[instance]
            .get(endpoint, formData)
            .catch((err) => {
              return err.response;
            });
          break;

        case "post":
          response = await Auth[instance]
            .post(endpoint, formData, config)
            .catch((err) => {
              return err.response;
            });
          break;

        case "put":
          response = await Auth[instance]
            .put(endpoint, formData)
            .catch((err) => {
              return err.response;
            });
          break;
        case "delete":
          response = await Auth[instance].delete(endpoint).catch((err) => {
            return err.response;
          });
          break;

        default:
          response = "";
      }
      if (isPublic) {
      } else {
        if (token) {
          // setting 2fa token
          Auth[instance].defaults.headers.common["Authorization"] = token;
          callBack(true);
          return;
        }
      }
      if (!response) {
        // network issue
        if (time <= 512) {
          callBack(false, Auth.codes[600]);
          let timer = setTimeout(() => {
            httpRequest({
              type: type,
              endpoint: endpoint,
              formData: formData,
              token: token,
              callBack: callBack,
              time: time * 2,
              instance,
            });
            clearTimeout(timer);
          }, time * 1000);
        } else {
          callBack(false, Auth.codes[403]);
        }
        return;
      } else {
        let msg = "";

        if (response.status === 200) {
          msg = Auth.codes[response.data.code];
          callBack(response.data, msg);
          return;
        } else if (response.status === 500 || response.status === 422) {
          callBack(false, response.data.message || "Something went wrong");
          return;
        } else {
          response = response.data  && response.data.code;
          if (response !== 403) {
            callBack(false, Auth.codes[response]);
            return;
          }
        }

        if (response === 403) {
          // bad token
          response = await Auth.updateAccessToken(); // false for bad refresh token, true for receiving access token
          if (response) {
            // callBack(true)
            httpRequest({
              type: type,
              endpoint: endpoint,
              formData: formData,
              token: token,
              callBack: callBack,
              instance,
            });
            return;
          } else {
            callBack(false, Auth.codes[403]);
            return;
          }
        }
      }
  },

  async updateAccessToken() {
    Auth.instance.defaults.headers.common["Authorization"] = Auth.refreshToken;
    Auth.instanceTwo.defaults.headers.common["Authorization"] = Auth.refreshToken;
    let response ;
    try {
      response = await Auth.instanceTwo.post("getNewToken");
    } catch(err){
      response = err.response
    }
    if (response && response.data && response.data.accessToken) {
      Auth.instance.defaults.headers.common["Authorization"] =
      response.data.accessToken;
    Auth.instance.CancelToken = axios.CancelToken;
      Auth.instanceTwo.defaults.headers.common["Authorization"] =
        response.data.accessToken;
      Auth.instanceTwo.CancelToken = axios.CancelToken;
      Auth.refreshToken = response.data.refreshToken;
      return true;
    } else if (response.data.code === 403) {
      localStorage.clear();
      // window.location.href = HOME;
      window.location.href = SIGNIN;
    }
    return false;
  },

  setThemeColor(val) {
    Auth.themeColor = val;
    sessionStorage.setItem("themeColor", val);
  },

  getAuthData() {
    let isAuthenticated = Auth.isAuthenticated;
    let isGoogleAuth = Auth.isGoogleAuth;
    let isKyc = Auth.isKyc;
    let isIpWhiteList = Auth.isIpWhiteList;
    let referralCode = Auth.referralCode;
    let email = Auth.email;
    let phoneNumber = Auth.phoneNumber;
    let themeColor = Auth.themeColor;
    let fullName = Auth.fullName;
    let address = Auth.address;

    return {
      isAuthenticated,
      isGoogleAuth,
      isKyc,
      isIpWhiteList,
      referralCode,
      email,
      phoneNumber,
      themeColor,
      fullName,
      address,
    };
  },
};

const io = require("socket.io-client");
// "http://localhost:3002", -> Local
// "http://3.98.211.88:3004", -> Demo
//"https://cryptoati.io", -> Live
export const socket = io.connect(
  "https://cryptoati.io",
  { path: "/community/socket.io", secure: true, transports: ['websocket'] }
);

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

socket.on('connect', function() {
  console.log("Connected to WS server");
});

export default Auth;
export const httpRequest = Auth.httpRequest;
export const isAuth = Auth.isAuth;
export const authenticate = Auth.authenticate;
export const getAuthData = Auth.getAuthData;
export const setThemeColor = Auth.setThemeColor;
export const themeColor = Auth.themeColor;
