import React from 'react'
//router
import { Switch,Route, useHistory } from 'react-router-dom'
import { ADMIN, ADMIN_URLS, APPROVE_IP, CONFIRM_MAIL, ERROR404, ERROR500, HOME, LOCK_SCREEN, RECOVER_PW, SIGNIN, SIGNUP, VERIFY_ACCOUNT } from '../Constants';
import { checkAdmin } from '../utils';

//layoutpages
const Home = React.lazy(() => import('../layouts/dashboard/home'));
const SignIn = React.lazy(() => import('../views/dashboard/auth/sign-in'));
const ConfirmMail = React.lazy(() => import('../views/dashboard/auth/confirm-mail'));
const LockScreen = React.lazy(() => import('../views/dashboard/auth/lock-screen'));
const Recoverpw = React.lazy(() => import('../views/dashboard/auth/recoverpw'));
const SignUp = React.lazy(() => import('../views/dashboard/auth/sign-up'));
const Error404 = React.lazy(() => import('../views/dashboard/errors/error404'));
const Error500 = React.lazy(() => import('../views/dashboard/errors/error500'));
const AccountVerify = React.lazy(() => import('../views/dashboard/auth/account-verify'));
const ApproveIP = React.lazy(() => import('../views/dashboard/auth/approve-ip'));

const IndexRouters = () => {
    const history = useHistory();
    let isAdmin = checkAdmin();
    if (!isAdmin && ADMIN_URLS.some(url => url === window.location.pathname)) {
        localStorage.setItem("userData", "");
        history.push(SIGNIN);
    }
    return (
        <>
            <Switch>
                <Route exact path={SIGNIN} component={SignIn}></Route>
                
                {/* auth */}
                <Route  path={CONFIRM_MAIL} component={ConfirmMail}/>
                <Route  path={LOCK_SCREEN} component={LockScreen}/>
                <Route  path={RECOVER_PW} component={Recoverpw}/>
                <Route  path={VERIFY_ACCOUNT} component={AccountVerify} />
                <Route  path={APPROVE_IP} component={ApproveIP} />
                <Route  path={SIGNIN} component={SignIn}/>
                <Route  path={SIGNUP} component={SignUp}/>  
                
                {/* error */}
                <Route  path={ERROR404} component={Error404}/>  
                <Route  path={ERROR500} component={Error500}/>

                {/* other */}
                <Route path={HOME} component={Home}/>
                {/* <Route path={PROFILE} component={UserProfile} /> */}
                <Route path={ADMIN} component={Home}/>
            </Switch>
        </>
    )
}

export default IndexRouters
